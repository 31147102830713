import React from "react";
import "./BreadCrumb.css";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

export const BreadCrumb = () => {
	const location = useLocation();
	const urlParams = new URLSearchParams(window.location.search);
	const paymentId = urlParams.get("paymentId");
	const payerId = urlParams.get("PayerID");
	const pathnames = location.pathname?.split("/")?.filter((value) => value);
	if (pathnames?.includes("success") || pathnames?.includes("failed")) {
		pathnames?.pop();
	}

	const { category, subCategory } = useSelector((state) => state?.multiProduct?.breadcrumb);

	const breadCrumbPath = [
		"wishlist",
		"contact-us",
		"about-us",
		"privacy-policy",
		"return-policy",
		"shipping-policy",
		"cart",
		"checkout",
		"my-account",
		"diamond-earring",
		"blog",
		"faq's",
		"complete-ring",
		"complete-earring",
		"complete-necklace",
		"terms-and-conditions",
		"gifts",
		"forgot-password",
		"cut",
		"color",
		"clarity",
		"carat",
		"certificate",
		"cost",
		"round",
		"princess",
		"cushion",
		"oval",
		"emerald",
		"pear",
		"asscher",
		"radiant",
		"marquies",
		"heart",
	];
	const hideBreadcrumbsRoutes = [
		"login",
		"SignUp",
		0,
		"gemstone",
		"diamond",
		"page-404",
		"engagement-rings",
		"wedding-rings",
		"register",
		"jewellery",
		"about-us",
		"gemstone-engagement-ring-settings",
		"gifts",
	];

	const renderBreadcrumbs = hideBreadcrumbsRoutes?.includes(pathnames[0] || pathnames.length);
	if (breadCrumbPath?.includes(...pathnames)) {
	} else {
		pathnames?.pop();
	}

	return (
		<>
			{renderBreadcrumbs ? null : (
				<div className="breadcrumb_main_div">
					<section className="breadcrumb-z7n style-S21UK" id="style-S21UK">
						<Container>
							<Row>
								<Col xl={12}>
									<div className="breadcrumb-hiz text-ynv fa-gpo ani-1sy style-qyJNJ" id="style-qyJNJ">
										<div className="breadcrumb-77p">
											<ul className="mb-0 justify-content-start">
												<li>
													<Link to="/">Home</Link>
												</li>
												<li>
													<i className="bi bi-chevron-right d-block"></i>
												</li>
												<li className="crub_name">
													{pathnames?.map((part) => {
														return part === "my-account" && payerId && paymentId ? (
															<React.Fragment key={part}>
																<Link style={{ marginRight: "10px" }} to={window?.location?.search}>
																	{part}
																</Link>
															</React.Fragment>
														) : part === "jewellery-detail" ? (
															<React.Fragment key={part}></React.Fragment>
														) : part === "my-account" ? (
															BreadCrumb
														) : (
															<React.Fragment key={part}>
																<Link style={{ marginRight: "10px" }} to={pathnames}>
																	{part}
																</Link>
															</React.Fragment>
														);
													})}
												</li>

												{pathnames?.map((part) => {
													return part === "jewellery-detail" ? (
														<React.Fragment key={part}>
															<li className="crub_name">
																<Link style={{ marginRight: "10px" }} to={pathnames}>
																	{category}
																</Link>
															</li>
															{subCategory ? (
																<>
																	<li>
																		<i className="bi bi-chevron-right d-block"></i>
																	</li>
																	<li className="crub_name">
																		<Link style={{ marginRight: "10px" }} to={pathnames}>
																			{subCategory}
																		</Link>
																	</li>
																</>
															) : (
																""
															)}
														</React.Fragment>
													) : (
														<React.Fragment key={part}></React.Fragment>
													);
												})}
											</ul>
										</div>
									</div>
								</Col>
							</Row>
						</Container>
					</section>
				</div>
			)}
		</>
	);
};
