import { TYPE } from "../../constants";

export const login = (payload) => {
	return {
		type: TYPE.LOGIN_ACTION,
		payload,
	};
};

export const logout = () => {
	return {
		type: TYPE.LOGOUT_ACTION,
	};
};

export const addToWishList = (product, type) => {
	return {
		type: type,
		payload: product,
	};
};

export const removeFromWishList = (id, type) => {
	return {
		type: type,
		payload: id,
	};
};

export const wishListCount = (count, type) => {
	return {
		type: type,
		payload: count,
	};
};

export const addToCartCount = (count, type) => {
	return {
		type: type,
		payload: count,
	};
};

export const addMultiProduct = (product, type) => {
	return {
		type: type,
		payload: product,
	};
};

export const addMultiProductGemstone = (product, type) => {
	return {
		type: type,
		payload: product,
	};
};

export const deleteMultiProductRing = (id, type) => {
	return {
		type: type,
		payload: id,
	};
};

export const deleteMultiProductDiamond = (id, type) => {
	return {
		type: type,
		payload: id,
	};
};

export const deleteMultiProductGemstone = (id, type) => {
	return {
		type: type,
		payload: id,
	};
};

export const multiProductFlag = (name, type) => {
	return {
		type: type,
		payload: name,
	};
};

export const startWithGemstone = (name, type) => {
	return {
		type: type,
		payload: name,
	};
};

export const deleteStartWithGemstone = (name, type) => {
	return {
		type: type,
		payload: name,
	};
};

export const diamondSettingShowOrNot = (name, type) => {
	return {
		type: type,
		payload: name,
	};
};

export const makeJewellery = (name, type) => {
	return {
		type: type,
		payload: name,
	};
};

export const multiProductSettingShowOrNot = (name, type) => {
	return {
		type: type,
		payload: name,
	};
};

export const headerLinkShoworNot = (name, type) => {
	return {
		type: type,
		payload: name,
	};
};

export const appSettings = (name, type) => {
	return {
		type: type,
		payload: name,
	};
};

export const allCategory = (name, type) => {
	return {
		type: type,
		payload: name,
	};
};
export const breadcrumb = (name, type) => {
	return {
		type: type,
		payload: name,
	};
};
