import { TYPE } from "../../constants";

const initialState = {
	ringData: {},
	diamondData: {},
	gemstoneData: {},
	type: "",
	startWith: "",
	diamondSetting: false,
	makeJewellery: "",
	multiSettingShoworNot: false,
	headersLink: [],
	appSettings: {
		social_link: {
			facebook: "",
			twitter: "",
			instagram: "",
			linkedin: "",
			skype: "",
		},
		general: {
			cancel_days: "",
			logo: "",
			returnDays: "",
			company_name: "",
			deliveryDays: "",
			email: "",
			contact_number: "",
			address: "",
		},
		fixed_price: {
			engraving_price: "",
		},
	},
	allCategory: [],
	breadcrumb: {},
};

const multiReducer = (state = initialState, action) => {
	switch (action.type) {
		case TYPE.ADD_RING:
			return {
				...state,
				ringData: action?.payload,
			};
		case TYPE.ADD_DIAMOND:
			return {
				...state,
				diamondData: action?.payload,
			};
		case TYPE.ALL_CATEGORY:
			return {
				...state,
				allCategory: action?.payload,
			};

		case TYPE.ADD_GEMSTONE:
			return {
				...state,
				gemstoneData: action?.payload,
			};

		case TYPE.DELETE_RING:
			return {
				...state,
				ringData: {},
			};
		case TYPE.HEADER_LINKS:
			return {
				...state,
				headersLink: action?.payload,
			};

		case TYPE.DELETE_DIAMOND:
			return {
				...state,
				diamondData: {},
			};

		case TYPE.DELETE_GEMSTONE:
			return {
				...state,
				gemstoneData: {},
			};

		case TYPE.MULTIPRODUCT_FLAG:
			return {
				...state,
				type: action?.payload,
			};

		case TYPE.START_WITH_GEMSTONE:
			return {
				...state,
				startWith: action?.payload,
			};

		case TYPE.DELETE_START_WITH_GEMSTONE:
			return {
				...state,
				startWith: "",
			};

		case TYPE.DIAMOND_SETTING_SHOW_OR_NOT:
			return {
				...state,
				diamondSetting: true,
			};

		case TYPE.DELETE_DIAMOND_SETTING_SHOW_OR_NOT:
			return {
				...state,
				diamondSetting: false,
			};

		case TYPE.MAKE_JEWELLERY:
			return {
				...state,
				makeJewellery: action?.payload,
			};

		case TYPE.MULTI_PRODUCT_FILTER_SHOW_OR_NOT:
			return {
				...state,
				multiSettingShoworNot: action?.payload,
			};

		case TYPE.GET_APP_SETTINGS:
			return {
				...state,
				appSettings: action?.payload,
			};
		case TYPE.BREADCRUMB:
			return {
				...state,
				breadcrumb: action?.payload,
			};

		default:
			return state;
	}
};

export default multiReducer;
