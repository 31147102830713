// this is file for storing redux actions types
export const LOGIN_ACTION = "LOGIN_ACTION";
export const LOGOUT_ACTION = "LOGOUT_ACTION";
export const ADD_TO_GEMSTONE = "ADD_TO_GEMSTONE";
export const ADD_TO_DAIMOND = "ADD_TO_DAIMOND";
export const REMOVE_GEMSTONE = "REMOVE_GEMSTONE";
export const REMOVE_DIAMOND = "REMOVE_DIAMOND";
export const WISHLIST_COUNT = "WISHLIST_COUNT";
export const ADD_TO_CART_COUNT = "ADD_TO_CART_COUNT";

export const ADD_RING = "ADD_RING";
export const DELETE_RING = "DELETE_RING";
export const DELETE_DIAMOND = "DELETE_DIAMOND";
export const ADD_DIAMOND = "ADD_DIAMOND";
export const ADD_GEMSTONE = "ADD_GEMSTONE";
export const DELETE_GEMSTONE = "DELETE_GEMSTONE";

export const MULTIPRODUCT_FLAG = "MULTIPRODUCT_FLAG";
export const START_WITH_GEMSTONE = "START_WITH_GEMSTONE";
export const DELETE_START_WITH_GEMSTONE = "DELETE_START_WITH_GEMSTONE";
export const DIAMOND_SETTING_SHOW_OR_NOT = "DIAMOND_SETTING_SHOW_OR_NOT";
export const DELETE_DIAMOND_SETTING_SHOW_OR_NOT = "DELETE_DIAMOND_SETTING_SHOW_OR_NOT";

export const MAKE_JEWELLERY = "MAKE_JEWELLERY";

export const MULTI_PRODUCT_FILTER_SHOW_OR_NOT = "MULTI_PRODUCT_FILTER_SHOW_OR_NOT";
export const HEADER_LINKS = "HEADER_LINKS";

export const GET_APP_SETTINGS = "GET_APP_SETTINGS";
export const ALL_CATEGORY = "ALL_CATEGORY";
export const BREADCRUMB = "BREADCRUMB";
